import React, { Component } from "react"
import logo from "./assets/planning-center-badge-color.svg"
import logoCalendar from "./assets/calendar-badge-color.svg"
import logoCheckins from "./assets/check-ins-badge-color.svg"
import logoGiving from "./assets/giving-badge-color.svg"
import logoGroups from "./assets/groups-badge-color.svg"
import logoHome from "./assets/home-badge-color.svg"
import logoPeople from "./assets/people-badge-color.svg"
import logoPublishing from "./assets/publishing-badge-color.svg"
import logoRegistrations from "./assets/registrations-badge-color.svg"
import logoServices from "./assets/services-badge-color.svg"
import "./App.css"

function Accounts({ color }) {
  return (
    <path
      fill={color}
      d="M16.95,9.28h0l-1.33-.23c-.12,0-.24-.04-.34-.11-.1-.07-.18-.16-.23-.27-.12-.45-.3-.89-.54-1.3-.04-.11-.05-.24-.03-.35,.02-.12,.08-.23,.16-.32l.78-1.1h0s0-.03,0-.04l-.03-.02-.04-.04-.87-.87-.05-.05h0s-.02-.02-.03,0l-1.11,.78c-.09,.08-.2,.13-.32,.16-.12,.02-.24,.01-.35-.03-.41-.24-.84-.42-1.3-.54-.11-.05-.2-.13-.27-.23-.07-.1-.11-.22-.11-.34l-.22-1.33h0s-.01-.03-.02-.03h-1.41s-.02,0-.02,.02l-.23,1.34c0,.12-.04,.24-.11,.34-.07,.1-.16,.18-.27,.23-.45,.12-.89,.3-1.3,.54-.11,.04-.24,.05-.35,.03-.12-.02-.23-.08-.32-.16l-1.1-.78h0s-.02-.01-.03,0l-.02,.03-.04,.04-.87,.87-.05,.05h0s0,.03,0,.04l.78,1.11c.08,.09,.13,.2,.16,.32,.02,.12,.01,.24-.03,.35-.24,.4-.42,.84-.54,1.29-.05,.11-.13,.2-.23,.27-.1,.07-.22,.11-.34,.11l-1.33,.22h0s-.02,0-.02,.02v1.41s0,.02,.02,.02l1.34,.23c.12,0,.24,.05,.34,.11,.1,.07,.18,.16,.23,.27,.12,.45,.3,.89,.54,1.29,.04,.11,.05,.24,.03,.35-.02,.12-.08,.23-.16,.32l-.78,1.11s0,.02,0,.03h0l.05,.06,.87,.87,.04,.04,.02,.02s.02,0,.03,0h0l1.1-.79c.09-.08,.2-.13,.32-.16,.12-.02,.24-.01,.35,.03,.4,.24,.84,.42,1.29,.54,.11,.05,.2,.13,.27,.23,.07,.1,.11,.22,.11,.34l.23,1.34s0,.02,.02,.02h1.41s.02,0,.02-.02h0l.22-1.34c0-.12,.05-.24,.11-.34,.07-.1,.16-.18,.27-.23,.45-.12,.89-.3,1.29-.54,.11-.04,.24-.05,.35-.03,.12,.02,.23,.08,.32,.16l1.11,.78s.02,0,.03,0h0l.05-.06,.87-.87,.04-.04,.02-.02s0-.02,0-.03h0l-.78-1.11c-.08-.09-.13-.2-.16-.32-.02-.12-.01-.24,.03-.35,.24-.4,.42-.84,.54-1.29,.05-.11,.13-.2,.23-.27s.22-.11,.34-.11l1.34-.23s.02,0,.02-.02v-1.4s0-.02-.02-.02Zm-4.67,3.01c-.45,.45-1.03,.76-1.65,.88-.63,.12-1.27,.06-1.86-.18-.59-.24-1.09-.66-1.45-1.19-.35-.53-.54-1.15-.54-1.79s.19-1.26,.54-1.79c.35-.53,.86-.94,1.45-1.19,.59-.24,1.24-.31,1.86-.18,.63,.12,1.2,.43,1.65,.88,.3,.3,.54,.66,.7,1.05,.16,.39,.25,.81,.25,1.24s-.08,.84-.25,1.24c-.16,.39-.4,.75-.7,1.05Z"
    />
  )
}

class App extends Component {
  render() {
    return (
      <div className="app-list-bounds">
        <header className="masthead">
          <img src={logo} className="logo" alt="Logo" />
          <h1>Where are you going?</h1>
        </header>
        <article>
          <ul className="app-list">
            <li>
              <a
                href="https://calendar.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoCalendar} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
            <li>
              <a
                href="https://check-ins.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoCheckins} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
            <li>
              <a
                href="https://giving.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoGiving} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
            <li>
              <a
                href="https://groups.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoGroups} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
            <li>
              <a
                href="https://home.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoHome} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
            <li>
              <a
                href="https://people.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoPeople} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
            <li>
              <a
                href="https://publishing.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoPublishing} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
            <li>
              <a
                href="https://registrations.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoRegistrations} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
            <li>
              <a
                href="https://services.planningcenteronline.com"
                className="app-launch"
              >
                <div className="app-logo">
                  <img src={logoServices} alt="Logo" />
                </div>
                <div className="btn">Open</div>
              </a>
            </li>
          </ul>
          <hr
            style={{ borderBottom: "1px solid #f1f1f1", margin: "32px 0 16px" }}
          />
          <div>
            <a
              href="https://accounts.planningcenteronline.com"
              className="app-launch account-settings"
            >
              <div className="app-logo">
                <svg
                  height={22}
                  style={{ display: "block", marginRight: "6px" }}
                  viewBox="0 0 20 20"
                  width={22}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <Accounts color="#9e9e9e" key="key2" />
                </svg>
              </div>
              <p>Account settings</p>
            </a>
          </div>
        </article>
      </div>
    )
  }
}

export default App
